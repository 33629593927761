import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _2be19f64 = () => interopDefault(import('../pages/admin/index.vue' /* webpackChunkName: "pages/admin/index" */))
const _0fa9b650 = () => interopDefault(import('../pages/cupom.vue' /* webpackChunkName: "pages/cupom" */))
const _078e8352 = () => interopDefault(import('../pages/faq.vue' /* webpackChunkName: "pages/faq" */))
const _20cd2340 = () => interopDefault(import('../pages/perfil.vue' /* webpackChunkName: "pages/perfil" */))
const _4bf24d4c = () => interopDefault(import('../pages/regulamentos.vue' /* webpackChunkName: "pages/regulamentos" */))
const _4fa03c08 = () => interopDefault(import('../pages/vencedores.vue' /* webpackChunkName: "pages/vencedores" */))
const _a9a87c88 = () => interopDefault(import('../pages/admin/actions/index.vue' /* webpackChunkName: "pages/admin/actions/index" */))
const _6e2317c5 = () => interopDefault(import('../pages/admin/calculation.vue' /* webpackChunkName: "pages/admin/calculation" */))
const _f3028b82 = () => interopDefault(import('../pages/admin/commands/index.vue' /* webpackChunkName: "pages/admin/commands/index" */))
const _34805018 = () => interopDefault(import('../pages/admin/cpf-block/index.vue' /* webpackChunkName: "pages/admin/cpf-block/index" */))
const _797aa1e0 = () => interopDefault(import('../pages/admin/dashboard.vue' /* webpackChunkName: "pages/admin/dashboard" */))
const _ce4a2942 = () => interopDefault(import('../pages/admin/dispatches/index.vue' /* webpackChunkName: "pages/admin/dispatches/index" */))
const _ffb6dd72 = () => interopDefault(import('../pages/admin/email-templates/index.vue' /* webpackChunkName: "pages/admin/email-templates/index" */))
const _4c42717e = () => interopDefault(import('../pages/admin/exports/index.vue' /* webpackChunkName: "pages/admin/exports/index" */))
const _41c08a95 = () => interopDefault(import('../pages/admin/faq/index.vue' /* webpackChunkName: "pages/admin/faq/index" */))
const _4c244c02 = () => interopDefault(import('../pages/admin/indexes/index.vue' /* webpackChunkName: "pages/admin/indexes/index" */))
const _637821a4 = () => interopDefault(import('../pages/admin/invalid-prizes/index.vue' /* webpackChunkName: "pages/admin/invalid-prizes/index" */))
const _4a16751d = () => interopDefault(import('../pages/admin/invoices/index.vue' /* webpackChunkName: "pages/admin/invoices/index" */))
const _ab0897aa = () => interopDefault(import('../pages/admin/ips/index.vue' /* webpackChunkName: "pages/admin/ips/index" */))
const _8c2142fa = () => interopDefault(import('../pages/admin/permissions/index.vue' /* webpackChunkName: "pages/admin/permissions/index" */))
const _7b482bd0 = () => interopDefault(import('../pages/admin/prizes/index.vue' /* webpackChunkName: "pages/admin/prizes/index" */))
const _571a874e = () => interopDefault(import('../pages/admin/registrations/index.vue' /* webpackChunkName: "pages/admin/registrations/index" */))
const _de14dc70 = () => interopDefault(import('../pages/admin/regulations/index.vue' /* webpackChunkName: "pages/admin/regulations/index" */))
const _40a1ca52 = () => interopDefault(import('../pages/admin/statistics.vue' /* webpackChunkName: "pages/admin/statistics" */))
const _cb30d9b4 = () => interopDefault(import('../pages/admin/tickets/index.vue' /* webpackChunkName: "pages/admin/tickets/index" */))
const _9968045e = () => interopDefault(import('../pages/admin/tokens/index.vue' /* webpackChunkName: "pages/admin/tokens/index" */))
const _43be69dd = () => interopDefault(import('../pages/admin/total-tickets/index.vue' /* webpackChunkName: "pages/admin/total-tickets/index" */))
const _498c9e07 = () => interopDefault(import('../pages/admin/users/index.vue' /* webpackChunkName: "pages/admin/users/index" */))
const _01ee5fda = () => interopDefault(import('../pages/admin/winners/index.vue' /* webpackChunkName: "pages/admin/winners/index" */))
const _08d02258 = () => interopDefault(import('../pages/admin/winners-draw/index.vue' /* webpackChunkName: "pages/admin/winners-draw/index" */))
const _07d683a6 = () => interopDefault(import('../pages/auth/forgot.vue' /* webpackChunkName: "pages/auth/forgot" */))
const _bae31694 = () => interopDefault(import('../pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _c3de3f34 = () => interopDefault(import('../pages/auth/register.vue' /* webpackChunkName: "pages/auth/register" */))
const _6c92b9e6 = () => interopDefault(import('../pages/admin/auth/forgot.vue' /* webpackChunkName: "pages/admin/auth/forgot" */))
const _935b1314 = () => interopDefault(import('../pages/admin/auth/login.vue' /* webpackChunkName: "pages/admin/auth/login" */))
const _ab926982 = () => interopDefault(import('../pages/admin/dispatches/create.vue' /* webpackChunkName: "pages/admin/dispatches/create" */))
const _a7bc3b52 = () => interopDefault(import('../pages/admin/email-templates/create.vue' /* webpackChunkName: "pages/admin/email-templates/create" */))
const _1e0512c9 = () => interopDefault(import('../pages/admin/faq/create.vue' /* webpackChunkName: "pages/admin/faq/create" */))
const _951e1c14 = () => interopDefault(import('../pages/admin/regulations/create.vue' /* webpackChunkName: "pages/admin/regulations/create" */))
const _442ff1e6 = () => interopDefault(import('../pages/admin/tokens/create.vue' /* webpackChunkName: "pages/admin/tokens/create" */))
const _0fbb6d97 = () => interopDefault(import('../pages/admin/users/create.vue' /* webpackChunkName: "pages/admin/users/create" */))
const _c1cb932c = () => interopDefault(import('../pages/admin/winners-draw/create.vue' /* webpackChunkName: "pages/admin/winners-draw/create" */))
const _0f5d4a0e = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _52cfa0bd = () => interopDefault(import('../pages/admin/auth/reset/_token.vue' /* webpackChunkName: "pages/admin/auth/reset/_token" */))
const _0ec80050 = () => interopDefault(import('../pages/admin/email-templates/edit/_id.vue' /* webpackChunkName: "pages/admin/email-templates/edit/_id" */))
const _d6be477c = () => interopDefault(import('../pages/admin/faq/edit/_id.vue' /* webpackChunkName: "pages/admin/faq/edit/_id" */))
const _0091a42f = () => interopDefault(import('../pages/admin/regulations/edit/_id.vue' /* webpackChunkName: "pages/admin/regulations/edit/_id" */))
const _1ba888e0 = () => interopDefault(import('../pages/admin/users/edit/_id.vue' /* webpackChunkName: "pages/admin/users/edit/_id" */))
const _24fb9ba3 = () => interopDefault(import('../pages/admin/winners-draw/edit/_id.vue' /* webpackChunkName: "pages/admin/winners-draw/edit/_id" */))
const _4611f9b8 = () => interopDefault(import('../pages/admin/actions/_id.vue' /* webpackChunkName: "pages/admin/actions/_id" */))
const _b2cb8cf2 = () => interopDefault(import('../pages/admin/dispatches/_id.vue' /* webpackChunkName: "pages/admin/dispatches/_id" */))
const _4ebfe516 = () => interopDefault(import('../pages/admin/invalid-prizes/_id.vue' /* webpackChunkName: "pages/admin/invalid-prizes/_id" */))
const _0fedca76 = () => interopDefault(import('../pages/admin/invoices/_id.vue' /* webpackChunkName: "pages/admin/invoices/_id" */))
const _e8504142 = () => interopDefault(import('../pages/admin/ips/_ip.vue' /* webpackChunkName: "pages/admin/ips/_ip" */))
const _3f8d9280 = () => interopDefault(import('../pages/admin/prizes/_id.vue' /* webpackChunkName: "pages/admin/prizes/_id" */))
const _0e060101 = () => interopDefault(import('../pages/admin/registrations/_id.vue' /* webpackChunkName: "pages/admin/registrations/_id" */))
const _f299d936 = () => interopDefault(import('../pages/admin/validation/_prize.vue' /* webpackChunkName: "pages/admin/validation/_prize" */))
const _bdf5cb06 = () => interopDefault(import('../pages/auth/reset/_token.vue' /* webpackChunkName: "pages/auth/reset/_token" */))
const _16b2a8b8 = () => interopDefault(import('../pages/unsubscribe/_id.vue' /* webpackChunkName: "pages/unsubscribe/_id" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/admin",
    component: _2be19f64,
    name: "admin"
  }, {
    path: "/cupom",
    component: _0fa9b650,
    name: "cupom"
  }, {
    path: "/faq",
    component: _078e8352,
    name: "faq"
  }, {
    path: "/perfil",
    component: _20cd2340,
    name: "perfil"
  }, {
    path: "/regulamentos",
    component: _4bf24d4c,
    name: "regulamentos"
  }, {
    path: "/vencedores",
    component: _4fa03c08,
    name: "vencedores"
  }, {
    path: "/admin/actions",
    component: _a9a87c88,
    name: "admin-actions"
  }, {
    path: "/admin/calculation",
    component: _6e2317c5,
    name: "admin-calculation"
  }, {
    path: "/admin/commands",
    component: _f3028b82,
    name: "admin-commands"
  }, {
    path: "/admin/cpf-block",
    component: _34805018,
    name: "admin-cpf-block"
  }, {
    path: "/admin/dashboard",
    component: _797aa1e0,
    name: "admin-dashboard"
  }, {
    path: "/admin/dispatches",
    component: _ce4a2942,
    name: "admin-dispatches"
  }, {
    path: "/admin/email-templates",
    component: _ffb6dd72,
    name: "admin-email-templates"
  }, {
    path: "/admin/exports",
    component: _4c42717e,
    name: "admin-exports"
  }, {
    path: "/admin/faq",
    component: _41c08a95,
    name: "admin-faq"
  }, {
    path: "/admin/indexes",
    component: _4c244c02,
    name: "admin-indexes"
  }, {
    path: "/admin/invalid-prizes",
    component: _637821a4,
    name: "admin-invalid-prizes"
  }, {
    path: "/admin/invoices",
    component: _4a16751d,
    name: "admin-invoices"
  }, {
    path: "/admin/ips",
    component: _ab0897aa,
    name: "admin-ips"
  }, {
    path: "/admin/permissions",
    component: _8c2142fa,
    name: "admin-permissions"
  }, {
    path: "/admin/prizes",
    component: _7b482bd0,
    name: "admin-prizes"
  }, {
    path: "/admin/registrations",
    component: _571a874e,
    name: "admin-registrations"
  }, {
    path: "/admin/regulations",
    component: _de14dc70,
    name: "admin-regulations"
  }, {
    path: "/admin/statistics",
    component: _40a1ca52,
    name: "admin-statistics"
  }, {
    path: "/admin/tickets",
    component: _cb30d9b4,
    name: "admin-tickets"
  }, {
    path: "/admin/tokens",
    component: _9968045e,
    name: "admin-tokens"
  }, {
    path: "/admin/total-tickets",
    component: _43be69dd,
    name: "admin-total-tickets"
  }, {
    path: "/admin/users",
    component: _498c9e07,
    name: "admin-users"
  }, {
    path: "/admin/winners",
    component: _01ee5fda,
    name: "admin-winners"
  }, {
    path: "/admin/winners-draw",
    component: _08d02258,
    name: "admin-winners-draw"
  }, {
    path: "/forgot",
    component: _07d683a6,
    name: "auth-forgot"
  }, {
    path: "/login",
    component: _bae31694,
    name: "auth-login"
  }, {
    path: "/register",
    component: _c3de3f34,
    name: "auth-register"
  }, {
    path: "/admin/forgot",
    component: _6c92b9e6,
    name: "admin-auth-forgot"
  }, {
    path: "/admin/login",
    component: _935b1314,
    name: "admin-auth-login"
  }, {
    path: "/admin/dispatches/create",
    component: _ab926982,
    name: "admin-dispatches-create"
  }, {
    path: "/admin/email-templates/create",
    component: _a7bc3b52,
    name: "admin-email-templates-create"
  }, {
    path: "/admin/faq/create",
    component: _1e0512c9,
    name: "admin-faq-create"
  }, {
    path: "/admin/regulations/create",
    component: _951e1c14,
    name: "admin-regulations-create"
  }, {
    path: "/admin/tokens/create",
    component: _442ff1e6,
    name: "admin-tokens-create"
  }, {
    path: "/admin/users/create",
    component: _0fbb6d97,
    name: "admin-users-create"
  }, {
    path: "/admin/winners-draw/create",
    component: _c1cb932c,
    name: "admin-winners-draw-create"
  }, {
    path: "/",
    component: _0f5d4a0e,
    name: "index"
  }, {
    path: "/admin/reset/:token?",
    component: _52cfa0bd,
    name: "admin-auth-reset-token"
  }, {
    path: "/admin/email-templates/edit/:id",
    component: _0ec80050,
    name: "admin-email-templates-edit-id"
  }, {
    path: "/admin/faq/edit/:id?",
    component: _d6be477c,
    name: "admin-faq-edit-id"
  }, {
    path: "/admin/regulations/edit/:id?",
    component: _0091a42f,
    name: "admin-regulations-edit-id"
  }, {
    path: "/admin/users/edit/:id?",
    component: _1ba888e0,
    name: "admin-users-edit-id"
  }, {
    path: "/admin/winners-draw/edit/:id",
    component: _24fb9ba3,
    name: "admin-winners-draw-edit-id"
  }, {
    path: "/admin/actions/:id",
    component: _4611f9b8,
    name: "admin-actions-id"
  }, {
    path: "/admin/dispatches/:id",
    component: _b2cb8cf2,
    name: "admin-dispatches-id"
  }, {
    path: "/admin/invalid-prizes/:id?",
    component: _4ebfe516,
    name: "admin-invalid-prizes-id"
  }, {
    path: "/admin/invoices/:id",
    component: _0fedca76,
    name: "admin-invoices-id"
  }, {
    path: "/admin/ips/:ip",
    component: _e8504142,
    name: "admin-ips-ip"
  }, {
    path: "/admin/prizes/:id",
    component: _3f8d9280,
    name: "admin-prizes-id"
  }, {
    path: "/admin/registrations/:id",
    component: _0e060101,
    name: "admin-registrations-id"
  }, {
    path: "/admin/validation/:prize?",
    component: _f299d936,
    name: "admin-validation-prize"
  }, {
    path: "/reset/:token?",
    component: _bdf5cb06,
    name: "auth-reset-token"
  }, {
    path: "/unsubscribe/:id?",
    component: _16b2a8b8,
    name: "unsubscribe-id"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
